import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Donut = _resolveComponent("Donut")
  const _component_Icon = _resolveComponent("Icon")
  const _component_t = _resolveComponent("t")

  return (_ctx.processing)
    ? (_openBlock(), _createBlock(_component_Donut, { key: 0 }))
    : (_openBlock(), _createBlock("span", {
        key: 1,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.upload && _ctx.upload(...args))),
        class: "df-middle"
      }, [
        _createVNode(_component_Icon, {
          name: "picture",
          class: "mr-8"
        }),
        _createVNode(_component_t, {
          path: "postpublish_4",
          custom: ""
        }, {
          default: _withCtx(({td}) => [
            _createTextVNode(_toDisplayString(td || 'Image'), 1)
          ]),
          _: 1
        })
      ]))
}