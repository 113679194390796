
import emojiList from '@/modules/community/communities/emojis'
import { defineComponent } from 'vue'
import Pic from 'common/Pic.vue'

export default defineComponent(
  {
    name: 'TheEmoji',
    components: { Pic },
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    emits: ['update:value'],
    data () {
      const list: Array<{ src: string; name: string }> = []
      return {
        list,
      }
    },
    methods: {
      getList () {
        emojiList.forEach(a => {
          this.list.push({ src: `/emoji/${a}.png`, name: a })
        })
      },

      // 去:emoji:
      regExp (t: string) {
        const Exp = /^:[^:]+:/
        let val = this.reverse(t)
        val = !Exp.test(val) ? val.slice(1) : val.replace(Exp, '')
        return this.reverse(val)
      },

      reverse (t: string) {
        return t.trim().split('').reverse().join('')
      },

      pushEmoji (n: string) {
        this.updateVal(`${this.value}:${n}:`)
      },

      delEmoji () {
        const n = this.regExp(this.value)
        this.updateVal(n)
      },

      updateVal (t: string) {
        this.$emit('update:value', t)
      },
    },
    created () {
      this.getList()
    },
  },
)
