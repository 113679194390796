
import { uploadImg } from '@/modules/community/communities/community'
import Donut from 'common/loading/Donut.vue'
import { defineComponent, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent(
  {
    name: 'TheImageTool',
    components: { Donut, Icon },
    emits: ['use'],
    setup (props, ctx) {
      const processing = shallowRef(false)

      const upload = async () => {
        processing.value = true
        const path = await uploadImg(false)
          .finally(() => {
            processing.value = false
          })
        if (path) ctx.emit('use', path)
      }

      return {
        processing,
        upload,
      }
    },
  },
)
