
import { translate } from '@/i18n'
import { useInput } from '@/modules/community/communities/community'
import { showAlert } from '@/components/popup/popup'
import { computed, shallowRef, onMounted, defineComponent, watch } from 'vue'
import TheEmoji from '@/modules/community/communities/components/TheEmoji.vue'
import TheImageTool from '@/modules/community/communities/components/TheImageTool.vue'
import Icon from '@/components/Icon.vue'
import TheImgGallery from '@/modules/community/communities/components/TheImgGallery.vue'

export default defineComponent(
  {
    name: 'TheInputTool',
    components: { TheImgGallery, Icon, TheImageTool, TheEmoji },
    props: {
      textLimit: {
        type: Number,
        default: 1000,
      },
      imagePreview: Boolean,
      maxImages: {
        type: Number,
        default: 3,
      },
      content: {
        type: String,
        required: true,
      },
      useImage: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['useImages', 'update:images', 'update:content'],
    setup (props, ctx) {
      const textarea = shallowRef<HTMLTextAreaElement | null>(null)
      const { text, images, mutateImg } = useInput()
      const typed = computed(() => text.value.length)
      const useEmoji = shallowRef(false)
      const isMaxImgUsed = computed(() => images.value.length >= props.maxImages)

      onMounted(() => {
        (textarea.value as HTMLTextAreaElement).focus()
      })

      watch(() => props.content, () => {
        text.value = props.content
      })

      watch(text, () => {
        ctx.emit('update:content', text.value)
      })

      watch(images, () => {
        ctx.emit('useImages', images.value)
        ctx.emit('update:images', images.value)
      })

      function useImg (imgSrc: string) {
        if (isMaxImgUsed.value) {
          showAlert(translate('toast_6', 'You can only add 3 images at most'))
        } else {
          mutateImg(imgSrc)
        }
      }

      return {
        textarea,
        text,
        typed,
        images,
        useImg,
        mutateImg,
        useEmoji,
      }
    },
  },
)
